/**
 * ==================== @ BASE STYLES
 */

// @font-face {
//   font-family: 'Montserrat';
//   src: url(path('montserrat-regular.woff2', $font-path)) format('woff2'),
//        url(path('montserrat-regular.woff', $font-path)) format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

html {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  background-color: #1C355E;
  color: $c-white;
  font: $fw-regular em($default-font-size, 16) $default-font-stack;
  font-family: 'Muli', 'Arial', sans-serif;
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0;

  &.is-overlayed {
    overflow: hidden;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

figure {
  margin: 0;
}

iframe { border: 0 none; }

img {
  height: auto;
  max-width: 100%;
}

input,
textarea,
select { border-radius: 0; }

.sv-g [class*="sv-u"] { font-family: $default-font-stack; }

.svg-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  vertical-align: -0.2em; // https://fvsch.com/code/svg-icons/how-to/#section-styling
  width: 1em;
}
