/**
 * Use BEM : https://en.bem.info/
 */
/**
 * ==================== @ THEME VARIABLES
 */
/**
 * ==================== @ SASS FUNCTIONS
 */
/**
 * ==================== @ SASS MIXINS
 */
/**
 * ==================== @ SASS PLACEHOLDERS
 */
.l-clearfix::before, .l-clearfix::after {
  content: " ";
  display: table; }

.l-clearfix::after {
  clear: both; }

/**
 * ==================== @ BASE STYLES
 */
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

body {
  background-color: #1C355E;
  color: #ffffff;
  font: 400 1em -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-family: 'Muli', 'Arial', sans-serif;
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0; }
  body.is-overlayed {
    overflow: hidden; }

a {
  color: inherit;
  text-decoration: none; }

button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0; }

figure {
  margin: 0; }

iframe {
  border: 0 none; }

img {
  height: auto;
  max-width: 100%; }

input,
textarea,
select {
  border-radius: 0; }

.sv-g [class*="sv-u"] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.svg-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  vertical-align: -0.2em;
  width: 1em; }

/**
 * ==================== @ GENERIC FLOW TOOLS
 */
.l-wrapper {
  padding: 0;
  margin: 0 auto;
  position: relative;
  max-width: 1600px; }

.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0; }

.l-untitle {
  font-size: inherit;
  font-weight: inherit;
  margin: 0; }

/* --- EMBED --- */
.l-embed {
  position: relative;
  padding-bottom: 56.25%;
  /* ratio 16.9 */
  height: 0; }

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* --- GRID --- */
/**
 * ==================== @ STATES
 */
.is-semantic {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  display: block;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.is-hidden {
  opacity: 0; }

header {
  background-color: #1D1D1D;
  color: #ffffff;
  overflow: hidden; }

button {
  float: right;
  color: #ffffff;
  padding: 16px; }
  button:hover {
    background-color: black; }
  button .svg-icon {
    margin-left: 5px;
    vertical-align: top; }

.dropdown {
  background-color: #1D1D1D;
  padding: 5px 10px;
  position: absolute;
  min-width: 140px;
  z-index: 2;
  right: 0;
  top: 49px;
  font-size: 0.8em;
  -webkit-transition: opacity .1s ease;
  -o-transition: opacity .1s ease;
  transition: opacity .1s ease; }
  .dropdown li {
    cursor: pointer;
    padding: 14px 10px; }
    .dropdown li:hover {
      color: #00B2E3; }
    .dropdown li img {
      margin-right: 5px;
      vertical-align: middle; }
    .dropdown li a {
      display: block;
      width: 100%; }

@media only screen and (min-width: 48em) {
  .dropdown li {
    padding: 6px 10px; } }

main {
  text-align: center;
  padding: 100px 30px 0 30px; }

.links {
  margin: 140px auto;
  position: relative; }

.caption span {
  text-transform: uppercase;
  font-size: 1.5em; }

.links__grid {
  font-size: 0;
  letter-spacing: -.36em;
  padding-bottom: 5%;
  margin-bottom: 3%;
  border-bottom: 3px solid #00B2E3; }

.links__item {
  display: inline-block;
  font-size: 16px;
  padding: 16px;
  width: 100%; }
  .links__item a {
    background-color: rgba(255, 255, 255, 0.05);
    outline: 1px solid #ffffff;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
    -webkit-transition: outline-color .1s step-end, outline-width .1s ease, outline-offset .1s ease;
    -o-transition: outline-color .1s step-end, outline-width .1s ease, outline-offset .1s ease;
    transition: outline-color .1s step-end, outline-width .1s ease, outline-offset .1s ease; }
    .links__item a:hover {
      outline-color: #00B2E3;
      outline-width: 6px;
      outline-offset: -6px;
      -webkit-transition: outline-width .1s ease, outline-offset .1s ease;
      -o-transition: outline-width .1s ease, outline-offset .1s ease;
      transition: outline-width .1s ease, outline-offset .1s ease; }
    .links__item a .svg-icon {
      height: 80%;
      left: 10%;
      position: absolute;
      top: 10%;
      width: 80%; }

.links__bottom {
  color: #d5d4d2;
  width: 80%;
  height: 50px; }

.bottom-text {
  color: #d5d4d2;
  font-style: italic; }

@media only screen and (min-width: 40em) {
  .links__item {
    width: -webkit-calc(100% / 3);
    width: calc(100% / 3); }
  .links__bottom {
    width: 50%; } }

@media only screen and (min-width: 48em) {
  .links {
    width: 80%; }
  .caption {
    width: 80%;
    max-width: 750px;
    margin: 0 auto; } }

@media only screen and (min-width: 64em) {
  main {
    padding-top: 140px; }
  .links {
    text-align: right; }
  .links__grid {
    margin-bottom: 1.2%; }
  .links__bottom {
    margin-right: 4%;
    width: 25%; } }

@media only screen and (min-width: 100em) {
  .caption {
    width: 60%;
    max-width: 1000px; }
  .links {
    width: 100%;
    max-width: 1200px; } }

footer {
  background-color: #1D1D1D;
  height: 50px; }
