main {
  text-align: center;
  padding: 100px 30px 0 30px;
}

.links {
  margin: 140px auto;
  position: relative;
}

.caption {
  span {
    text-transform: uppercase;
    font-size: 1.5em;
  }
}

.links__grid {
  font-size: 0;
  letter-spacing: -.36em;
  padding-bottom: 5%;
  margin-bottom: 3%;
  border-bottom: 3px solid $c-blue;
}

.links__item {
  display: inline-block;
  font-size: $default-font-size;
  padding: 16px;
  width: 100%;

  a {
    background-color: rgba(255,255,255,0.05);
    outline: 1px solid $c-white;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
    transition: outline-color .1s step-end, outline-width .1s ease, outline-offset .1s ease;

    &:hover {
      outline-color: $c-blue;
      outline-width: 6px;
      outline-offset: -6px;
      transition: outline-width .1s ease, outline-offset .1s ease;
    }

    .svg-icon {
      height: 80%;
      left: 10%;
      position: absolute;
      top: 10%;
      width: 80%;
    }
  }
}

.links__bottom {
  color: $c-grey;
  width: 80%;
  height: 50px;
}

.bottom-text {
  color: $c-grey;
  font-style: italic;
}


@media only screen and (min-width: $bp-s) {

  .links__item {
    width: calc(100% / 3);
  }

  .links__bottom {
    width: 50%
  }

}


@media only screen and (min-width: $bp-m) {

  .links {
    width: 80%;
  }

  .caption {
    width: 80%;
    max-width: 750px;
    margin: 0 auto;
  }

}


@media only screen and (min-width: $bp-l) {

  main  {
    padding-top: 140px;
  }

  .links {
    text-align: right;
  }

  .links__grid {
    margin-bottom: 1.2%;
  }

  .links__bottom {
    margin-right: 4%;
    width: 25%;
  }

}


@media only screen and (min-width: $bp-xl) {

  .caption {
    width: 60%;
    max-width: 1000px;
  }

  .links {
    width: 100%;
    max-width: 1200px;
  }

}
