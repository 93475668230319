header {
  background-color: $c-black;
  color: $c-white;
  overflow: hidden;
}

button {
  float: right;
  color: $c-white;
  padding: 16px;

  &:hover {
    background-color: black;
  }

  .svg-icon {
    margin-left: 5px;
    vertical-align: top;
  }
}

.dropdown {
  background-color: $c-black;
  padding: 5px 10px;
  position: absolute;
  min-width: 140px;
  z-index: 2;
  right: 0;
  top: 49px;
  font-size: 0.8em;
  transition: opacity .1s ease;

  li{
    cursor: pointer;
    padding: 14px 10px;

    &:hover {
      color: $c-blue;
    }

    img {
      margin-right: 5px;
      vertical-align: middle;
    }

    a {
      display: block;
      width: 100%;
    }
  }
}


@media only screen and (min-width: $bp-m) {

  .dropdown {
    li {
      padding: 6px 10px;
    }
  }
}
