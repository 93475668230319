/**
 * ==================== @ GENERIC FLOW TOOLS
 */

.l-clearfix { @extend %clearfix; }

.l-wrapper {
  padding: 0;
  margin: 0 auto;
  position: relative;
  max-width: 1600px;
}

.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.l-untitle {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

/* --- EMBED --- */
.l-embed {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16.9 */
  height: 0;

  // &.m--rXXX {} // Ratio modifier
}

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* --- GRID --- */
.l-grid {}

.l-grid__item {}
